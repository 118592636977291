<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <div class="image-tray">
    <div v-show="can_upload">
      <label v-bind:for="'new-image-' + component_id" class="new-image"
        v-bind:style="{ 'width': image_width_and_height + 'px', 'height': image_width_and_height + 'px', 'line-height': image_width_and_height + 'px' }">
        <div><i class="fas fa-plus-circle"></i></div>
        <input v-bind:id="'new-image-' + component_id" type="file" multiple style="display:none;" @change="upload_file">
      </label>
      <div class="images">
      </div>
    </div>
    <div class="image" v-for="image in images" v-bind:key="image.src"
      v-bind:style="{ 'width': image_width_and_height + 'px', 'height': image_width_and_height + 'px', 'border':((image_border)?'1px solid #e1e1e1':'none')}">
      <button class="file-delete btn bg-red" @click="delete_image(image.path)"><i class="fas fa-times"></i></button>
      <div class="image-contain"
        v-bind:style="{ 'width': image_width_and_height + 'px', 'height': image_width_and_height + 'px' }">
        <img v-bind:src="image.src"
          v-bind:style="{ 'width': (image_width_and_height - 2) + 'px', 'height': (image_width_and_height - 2) + 'px' }">
        <div class="progress" v-bind:class="{ 'd-none': (image.progress <= 0 || image.progress >= 100) }"
          v-bind:style="{ 'width': image.progress + '%' }"></div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

  <div class="invalid-mssage">{{ component_message }}</div>

  <div v-show="component_to_label">
    {{ component_value }}
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {
    let localMeta = this.field;
    if (localMeta.component.properties.show_label == undefined) {
      localMeta.component.properties.show_label = '';
    }


    let localValue = this.value;

    if (localValue == null || localValue == undefined || localValue == '') {
      localValue = [];
    }

    // 如果 localValue 是字串則轉成陣列
    else if (typeof localValue === 'string' || localValue instanceof String) {
      localValue = [localValue];
    }



    // 將帶入的資料轉換成圖片物件
    let images = [];
    for (let i = 0; i < localValue.length; i++) {
      images.push({
        'id': Math.random().toString().substring(2),
        'src': localValue[i],
        'progress': 0,
        'path': localValue[i]
      });
    }

    if (localMeta.component.properties.base_url != undefined && localMeta.component.properties.base_url != '') {
      for (let i = 0; i < images.length; i++) {
        images[i].src = localMeta.component.properties.base_url + images[i].src;
      }

    }

    // 如果沒有設定上傳數量，則預設為無限
    if (localMeta.component.properties.count == undefined || localMeta.component.properties.count == 0) {
      localMeta.component.properties.count = 10000000000;
    }

    // 如果沒有設定上傳容量，則預設為無限
    if (localMeta.component.properties.max_size == undefined && localMeta.component.properties.max_size == '') {
      localMeta.component.properties.max_size = 0;
    }

    // 設置圖片寬高
    if (localMeta.component.properties.width_and_height == undefined || localMeta.component.properties.width_and_height == '') {
      localMeta.component.properties.width_and_height = 250;
    }

    // 上傳網址
    let api_url = '';
    if (localMeta.component.properties.upload_api != undefined) {
      // 組合 API 網址
      api_url = this.$microlink.base_api_url + localMeta.component.properties.upload_api;
    }

    // 是否可以上傳
    let can_upload = (localValue.length < localMeta.component.properties.count);

    return {
      // 元件識別碼
      component_id: 'image-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: localMeta,

      // 元件值
      component_value: localValue,

      // 元件訊息
      component_message: this.message,

      // 元件是否被標籤化
      component_to_label: (localMeta.component.properties.to_label == 'Y') ? true : false,

      // 圖片元件格式
      images: images,

      // 元件是否可以上傳
      can_upload: can_upload,

      // 上傳網址
      upload_url: api_url,

      // 上傳最大容量限制
      upload_max_size: localMeta.component.properties.max_size,

      // 圖片寬高
      image_width_and_height: localMeta.component.properties.width_and_height,

      // 是否顯示邊框
      image_border: (localMeta.component.properties.border == 'Y')
    }
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;
    }
  },
  methods: {
    upload_file: function ($event) {

      if ($event.target.files.length == 0) {
        return;
      }

      let $this = this;


      for (let i = 0; i < $event.target.files.length; i++) {
        let tempFile = $event.target.files[i]

        if (this.upload_max_size > 0) {
          // 將容量轉換成 KB
          let fileSize = Math.round(tempFile.size / 1024 * 100) / 100;

          // 檢查檔案大小
          if (fileSize > this.upload_max_size) {
            // 文件大小超限了
            this.$microlink.alert('檔案大小超過限制', '檔案名稱：' + tempFile.name, 'warning');
            continue;
          }
        }

        let file = new FormData()
        file.append('file', tempFile)

        let image_id = Math.random().toString().substring(2);
        let image = { 'src': URL.createObjectURL(tempFile), 'progress': 0, 'id': image_id, 'path': '' };
        this.images.push(image);

        $event.target.value = '';

        axios({
          method: 'post',
          url: this.upload_url,
          data: file,
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function (progressEvent) {
            const complete = parseInt((progressEvent.loaded / progressEvent.total * 100 | 0));

            // 快速根據 image_id 找到對應的圖片物件
            let image = $this.images.find(image => image.id == image_id);
            image.progress = complete;

          }
        }).then(res => {

          let response = res.data;
          if (response.status == 'success') {
            // 將上傳成功的圖片網址加入到 images 陣列中
            let image = $this.images.find(image => image.id == image_id);
            image.src = response.data.url;
            image.progress = 100;
            image.path = response.data.path;
            $this.input_event();

            // 如果上傳成功的圖片數量已經達到上限，則不允許再上傳
            if ($this.component_value.length >= $this.component_meta.component.properties.count) {
              $this.can_upload = false;
            }

            // 將元件值傳出去
            // $this.$emit('input', $this.component_value);
          } else {
            // 上傳失敗
            $this.$microlink.alert('上傳失敗', response.message, 'error');
          }


        }).catch(err => {
          // 捕獲異常並處理
          console.log(err)
        });
      }
    },
    delete_image: function (image_path) {
      // 詢問是否確定刪除
      this.$microlink.confirm('確定要刪除嗎？', '刪除後無法復原', (result) => {
        if (result.isConfirmed) {
          // 將圖片路徑轉換成 base64
          let image_path_64 = this.$microlink.base64_encode(image_path);

          // 取得刪除的 API 網址
          let api_url = this.$microlink.base_api_url + this.component_meta.component.properties.delete_api;
          this.$microlink.api_post(api_url, { 'fd': image_path_64 }, true, (response) => {

            if (response.status == 'success') {
              // 刪除成功
              this.$microlink.alert('刪除成功', response.message, 'success');

              // 將圖片從 images 陣列中移除
              let image = this.images.find(image => image.path == image_path);
              this.images.splice(this.images.indexOf(image), 1);

              // 將元件值傳出去
              this.input_event();

              // 如果上傳成功的圖片數量已經達到上限，則不允許再上傳
              if (this.component_value.length < this.component_meta.component.properties.count) {
                this.can_upload = true;
              }
            } else {
              // 刪除失敗
              this.$microlink.alert('刪除失敗', response.message, 'error');
            }
          });
        }
      });
    },
    input_event: function () {

      let $event = {};
      if (this.images.length == 0) {
        this.component_value = '';
        $event = { 'target': { 'value': '' } };
        $event.target.value = this.component_value;
      }
      else if (this.images.length == 1) {
        if (this.images[0].path == undefined || this.images[0].path == '') {
          return;
        }
        this.component_value = this.images[0].path;

        $event = { 'target': { 'value': '' } };
        $event.target.value = this.component_value;
      }
      else {
        this.component_value = [];
        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i].path == undefined || this.images[i].path == '') {
            continue;
          }
          this.component_value.push(this.images[i].path);
        }
        $event = { 'temp': [] };
        $event.temp = this.component_value;
      }

      this.$emit('input', $event)
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

div.invalid-mssage {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}

td i.fa-grip-vertical {
  cursor: pointer;
}

label.new-image {
  border: 4px dotted #17a2b8;
  text-align: center;
  vertical-align: middle;
  font-size: 45px;
  color: #17a2b8;
  cursor: pointer;
  border-radius: 5px;
  float: left;
  margin: 10px;
}

div.image {
  border: 1px solid #e1e1e1;
  width: 250px;
  height: 250px;
  float: left;
  margin: 10px;
  border-radius: 5px;
  position: relative;
}

div.image button {
  border-radius: 30px;
  position: absolute;
  width: 25px;
  height: 25px;
  padding: 0px 7px;
  right: -13px;
  top: -13px;
  z-index: 10;
}

div.image-contain {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

div.image img {
  width: 248px;
  height: 248px;
  object-fit: contain;
}

div.image div.progress {
  position: absolute;
  bottom: 2px;
  left: -2px;
  width: 100%;
  height: 5px;
  background-color: #d7de71;
}
</style>